.company-row {
  background-color: #6527be;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.company-row h3 {
  color: white;
}
