.counter {
  font-size: 2rem;
  font-weight: 600;
  color: #0a2b1e;
}

.counter__title {
  font-size: 1rem;
  color: #0a2b1e;
  font-weight: 500;
}

.about__content {
  padding-left: 50px;
}

.about__img img {
  border-radius: 15px;
}

.about__content h2 {
  font-weight: 600;
}
.single__counter {
  margin: 0 15px;
}
