* {
  margin: 0;
  padding: 0;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 90px;
}

.nav ul {
  display: flex;
  justify-content: center;
}

.nav ul li {
  list-style: none;
  margin: 0 23px;
}

.nav ul li a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
  font-weight: bold;
}

.nav ul li a:hover {
  color: #6527be;
}

.left {
  font-size: 2.5rem;
  color: var(--color1);
}
